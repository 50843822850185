@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i,900|Montserrat:900);
:root {
  /* --primary-color: #d23669;
  --primary-color-light: rgb(255, 167, 196); */
  --primary-color: #32395c;
  --primary-color-light: #32395c;
}

* {
  box-sizing: border-box;
}

html {
  color: rgba(0, 0, 0, 0.9);
  font: 100%/1.75 Morriweather, Georgia, serif;
  overflow-x: hidden;
}

body {
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-family: 'Merriweather','Georgia',serif;
  font-feature-settings: "kern", "liga", "clig", "calt", "kern";
  font-weight: 400;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  position: relative;
  overflow-x: hidden;
}

pre,
code {
  font-family: Consolas, Menlo, Monaco, source-code-pro, "Courier New", monospace;
  font-size: 0.85rem;
  line-height: 1.5;
}

pre {
  border-radius: 10px;
  overflow: auto;
  padding: 1em;
}

a {
  color: #32395c;
  color: var(--primary-color);
}

a:hover {
  text-decoration: none;
}

p, pre, ul, ol {
  margin-top: 0;
  margin-bottom: 1.75rem;
}

img {
  max-width: 100%;
}

h1 {
  font-family: Montserrat, sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 1.7rem;
  margin-bottom: 2.4rem;
  text-rendering: optimizeLegibility;
}

#disqus_thread {
  overflow: hidden;
}

#disqus_thread iframe {
  margin-bottom: -54px !important;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

blockquote p {
  display: inline;
}

hr {
  background: hsla(0,0%,0%,0.2);
  border: none;
  height: 1px;
  margin: 2rem 0;
}

.mb-175 {
  margin-bottom: 1.75rem;
}

.ArticleMeta_tags__1IJ9w,
.ArticleMeta_tags__1IJ9w li {
  display: inline;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ArticleMeta_tags__1IJ9w li:not(:first-child)::before {
  content: ', ';
}
.ArticleSummary_ArticleSummary__3I5Qk h2 {
  font-weight: 900;
  margin-bottom: 0;
  margin-top: 0;
}

.ArticleSummary_ArticleSummary__3I5Qk a {
  text-decoration: none;
}
.Bio_Bio__1ks_2 {
  display: flex;
  margin-bottom: 4.375rem;
}
  
.Bio_Bio__1ks_2 img {
  border-radius: 50%;
  flex-shrink: 0;
  height: 3.5rem;
  margin-bottom: 0;
  margin-right: 0.875rem;
  width: 3.5rem;
}

.Bio_headerLinks__1ogsi {
  display: flex;
  flex-flow: row wrap;
}

.Bio_headerLinks__1ogsi .Bio_bull__1Yg9V {
  margin: 0 5px;
}

.Pagination_Pagination__2Xt2h {
  display: block;
  margin: 4.375rem 0 7rem;
}
  
.Pagination_Pagination__2Xt2h .Pagination_previous__1akUm {
  margin-right: 0.5rem;
}
.Pagination_Pagination__2Xt2h .Pagination_next__7Ba4Y {
  margin-left: 0.5rem;
}
.BlogIndexPage_title__341hM {
  font-family: Montserrat, sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin-top: 0;
  margin-bottom: 2.625rem;
  text-rendering: optimizeLegibility;
}
.BlogIndexPage_title__341hM a {
  color: black;
  text-decoration: none;
}

.BlogIndexPage_articlesList__1V39R,
.BlogIndexPage_articlesList__1V39R > li {
  list-style: none;
  padding: 0;
  margin: 0 0 2.625rem;;
}

.BlogIndexPage_footer__1vkZQ {
  margin-top: 7rem;
  margin-bottom: 0.5rem;
}

.LoadingIndicator_LoadingIndicator__3KSl0 {
  position: fixed;
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
  background-color: var(--primary-color-light);
  background-size: 35px 35px;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
  -webkit-transition: opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  transition: opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  transition: transform ease-in 300ms, opacity ease-in 300ms;
  transition: transform ease-in 300ms, opacity ease-in 300ms, -webkit-transform ease-in 300ms;
  -webkit-transition-delay: 0;
          transition-delay: 0;
  -webkit-transform-origin: left center;
          transform-origin: left center;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  opacity: 0;
}

.LoadingIndicator_LoadingIndicator__3KSl0.LoadingIndicator_active__3EncM {
  opacity: 1;
  -webkit-transition-delay: 333ms;
          transition-delay: 333ms;
  -webkit-animation: LoadingIndicator_LoadingIndicator-animation__2icwd 10s ease-out;
          animation: LoadingIndicator_LoadingIndicator-animation__2icwd 10s ease-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}

@-webkit-keyframes LoadingIndicator_LoadingIndicator-animation__2icwd {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  10% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
  }
  50% {
    -webkit-transform: scaleX(0.7);
            transform: scaleX(0.7);
  }
  90% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

@keyframes LoadingIndicator_LoadingIndicator-animation__2icwd {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
  }
  10% {
    -webkit-transform: scaleX(0.3);
            transform: scaleX(0.3);
  }
  50% {
    -webkit-transform: scaleX(0.7);
            transform: scaleX(0.7);
  }
  90% {
    -webkit-transform: scaleX(0.8);
            transform: scaleX(0.8);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
.BlogLayout_container__1fiRb {
  margin: 0 auto;
  max-width: 43.5rem;
  padding: 2.625rem 1.3125rem;
}

.BlogLayout_title__3HyDU {
  font-family: Montserrat, sans-serif;
  font-size: 1.44rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
}
.BlogLayout_title__3HyDU > a {
  color: var(--primary-color-light);
  text-decoration: none;
}

.BlogPostLayout_title__2L_2_ {
  font-family: Montserrat, sans-serif;
  font-weight: 900;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
}
.BlogPostLayout_title__2L_2_ > a {
  text-decoration: none;
}

.BlogPostLayout_header__1Ny4T {
  margin-bottom: 2.4rem;  
}
.BlogPostLayout_header__1Ny4T .BlogPostLayout_title__2L_2_ {
  font-size: 2.5rem;
  margin-top: 1.7rem;
  margin-bottom: 0;
}
.BlogPostLayout_header__1Ny4T .BlogPostLayout_title__2L_2_ > a {
  color: black;
}

.BlogPostLayout_footer__3ofxa {
  margin-top: 3rem;
}
.BlogPostLayout_footer__3ofxa .BlogPostLayout_title__2L_2_ {
  font-size: 1.44rem;
  margin-top: 0;
  margin-bottom: 2.4rem;
}
.BlogPostLayout_footer__3ofxa .BlogPostLayout_title__2L_2_ > a {
  color: var(--primary-color-light);
}

.BlogPostLayout_bio__diNn9 {
  margin-bottom: 7rem;
}

.BlogPostLayout_links__-LX2X {
  display: flex;
  justify-content: space-between;
}
.BlogPostLayout_links__-LX2X .BlogPostLayout_next__KrMHx {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
}

.BlogPostLayout_content__1mzTO hr {
  margin: 5rem 0;
}

.BlogPostLayout_content__1mzTO h2,
.BlogPostLayout_content__1mzTO h3,
.BlogPostLayout_content__1mzTO h4,
.BlogPostLayout_content__1mzTO h5,
.BlogPostLayout_content__1mzTO h6 {
  font-weight: 900;
  line-height: 1.1;
  margin-top: 3.5rem;
  text-rendering: optimizeLegibility;
}
  
/**
* Based on copypasta from Remy Bach and Sarah Drasner
*/
.BlogPostLayout_content__1mzTO code,
.BlogPostLayout_content__1mzTO pre {
  color: white;
  font-family: Consolas,Menlo,Monaco,source-code-pro,Courier New,monospace;
  font-feature-settings: normal;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
       tab-size: 4;
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
  
/* Code blocks */
.BlogPostLayout_content__1mzTO pre {
  overflow: auto;
  padding: 1em;
}

.BlogPostLayout_content__1mzTO :not(pre) > code,
.BlogPostLayout_content__1mzTO pre {
  background: hsla(0,0%,0%,0.9);
}
  
.BlogPostLayout_content__1mzTO pre::-moz-selection,
.BlogPostLayout_content__1mzTO pre::-moz-selection {
  background: hsl(207, 4%, 16%);
}
  
.BlogPostLayout_content__1mzTO pre::-moz-selection,
.BlogPostLayout_content__1mzTO pre::selection {
  background: hsl(207, 4%, 16%);
}
  
/* Text Selection colour */
.BlogPostLayout_content__1mzTO pre::-moz-selection,
.BlogPostLayout_content__1mzTO pre ::-moz-selection {
  text-shadow: none;
  background: hsla(0, 0%, 100%, 0.15);
}

.BlogPostLayout_content__1mzTO pre::selection,
.BlogPostLayout_content__1mzTO pre ::selection {
  text-shadow: none;
  background: hsla(0, 0%, 100%, 0.15);
}

/* Inline code */
.BlogPostLayout_content__1mzTO :not(pre) > code {
  border-radius: .3em;
  background: rgba(255,229,100,0.2);
  color: #1a1a1a;
  padding: .15em .2em .05em;
  white-space: normal;
}
.BlogPostLayout_content__1mzTO a > code {
  color: var(--primary-color);
}

.BlogPostLayout_content__1mzTO .highlighted-line {
  background-color: hsla(207, 95%, 15%, 1);
  display: block;
  margin-right: -1em;
  margin-left: -1em;
  padding-right: 1em;
  padding-left: 0.75em;
  border-left: 0.25em solid #ffa7c4;
}

.BlogPostLayout_content__1mzTO .token.attr-name {
  color: rgb(173, 219, 103);
  font-style: italic;
}

.BlogPostLayout_content__1mzTO .token.comment {
  color: rgb(99, 119, 119);
}

.BlogPostLayout_content__1mzTO .token.string,
.BlogPostLayout_content__1mzTO .token.url {
  color: rgb(173, 219, 103);
}

.BlogPostLayout_content__1mzTO .token.variable {
  color: rgb(214, 222, 235);
}

.BlogPostLayout_content__1mzTO .token.number {
  color: rgb(247, 140, 108);
}

.BlogPostLayout_content__1mzTO .token.builtin,
.BlogPostLayout_content__1mzTO .token.char,
.BlogPostLayout_content__1mzTO .token.constant,
.BlogPostLayout_content__1mzTO .token.function {
  color: rgb(130, 170, 255);
}

.BlogPostLayout_content__1mzTO .token.punctuation {
  color: rgb(199, 146, 234);
}

.BlogPostLayout_content__1mzTO .token.selector,
.BlogPostLayout_content__1mzTO .token.doctype {
  color: rgb(199, 146, 234);
  font-style: 'italic';
}

.BlogPostLayout_content__1mzTO .token.class-name {
  color: rgb(255, 203, 139);
}

.BlogPostLayout_content__1mzTO .token.tag,
.BlogPostLayout_content__1mzTO .token.operator,
.BlogPostLayout_content__1mzTO .token.keyword {
  color: #ffa7c4;
}

.BlogPostLayout_content__1mzTO .token.boolean {
  color: rgb(255, 88, 116);
}

.BlogPostLayout_content__1mzTO .token.property {
  color: rgb(128, 203, 196);
}

.BlogPostLayout_content__1mzTO .token.namespace {
  color: rgb(178, 204, 214);
}

.BlogPostLayout_postSocial__39vw3 {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 3rem;
}

.BlogPostLayout_socialButton__2-HYj {
  cursor: pointer;
  margin: 0.5rem 0.25rem;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
  padding: 0.25rem;
  border-radius: 2px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  outline: none !important;
}
.BlogPostLayout_socialButton__2-HYj:hover {
  color: #fff;
}
.BlogPostLayout_faIcon__3Cx32 {
  margin-right: 5px;
}
@media screen and (max-width: 700px) {
  .BlogPostLayout_faIconText__2gOji {
    display: none;
  }
  .BlogPostLayout_faIcon__3Cx32 {
    font-size: 2em;
    margin: 0;
  }
}

:root {
  --facebook: #3b5998;
  --twitter: #1da1f2;
  --linkedin: #0077b5;
  --reddit: #ff4500;
  --whatsapp: #128c7e;
  --telegram: #0088cc;
}

.BlogPostLayout_socialButton__2-HYj.BlogPostLayout_facebook__3Mqfp {
  color: #3b5998;
  color: var(--facebook);
  border-color: #3b5998;
  border-color: var(--facebook);
}
.BlogPostLayout_socialButton__2-HYj.BlogPostLayout_facebook__3Mqfp:hover {
  background-color: #3b5998;
  background-color: var(--facebook);
  border-color: #3b5998;
  border-color: var(--facebook);
  color: #fff;
}
.BlogPostLayout_socialButton__2-HYj.BlogPostLayout_twitter__1eeeJ {
  color: #1da1f2;
  color: var(--twitter);
  border-color: #1da1f2;
  border-color: var(--twitter);
}
.BlogPostLayout_socialButton__2-HYj.BlogPostLayout_twitter__1eeeJ:hover {
  background-color: #1da1f2;
  background-color: var(--twitter);
  border-color: #1da1f2;
  border-color: var(--twitter);
  color: #fff;
}
.BlogPostLayout_socialButton__2-HYj.BlogPostLayout_linkedin__2Eslc {
  color: #0077b5;
  color: var(--linkedin);
  border-color: #0077b5;
  border-color: var(--linkedin);
}
.BlogPostLayout_socialButton__2-HYj.BlogPostLayout_linkedin__2Eslc:hover {
  background-color: #0077b5;
  background-color: var(--linkedin);
  border-color: #0077b5;
  border-color: var(--linkedin);
  color: #fff;
}
.BlogPostLayout_socialButton__2-HYj.BlogPostLayout_reddit__DrZQ1 {
  color: #ff4500;
  color: var(--reddit);
  border-color: #ff4500;
  border-color: var(--reddit);
}
.BlogPostLayout_socialButton__2-HYj.BlogPostLayout_reddit__DrZQ1:hover {
  background-color: #ff4500;
  background-color: var(--reddit);
  border-color: #ff4500;
  border-color: var(--reddit);
  color: #fff;
}
.BlogPostLayout_socialButton__2-HYj.BlogPostLayout_whatsapp__30pt7 {
  color: #128c7e;
  color: var(--whatsapp);
  border-color: #128c7e;
  border-color: var(--whatsapp);
}
.BlogPostLayout_socialButton__2-HYj.BlogPostLayout_whatsapp__30pt7:hover {
  background-color: #128c7e;
  background-color: var(--whatsapp);
  border-color: #128c7e;
  border-color: var(--whatsapp);
  color: #fff;
}
.BlogPostLayout_socialButton__2-HYj.BlogPostLayout_telegram__1sxUm {
  color: #0088cc;
  color: var(--telegram);
  border-color: #0088cc;
  border-color: var(--telegram);
}
.BlogPostLayout_socialButton__2-HYj.BlogPostLayout_telegram__1sxUm:hover {
  background-color: #0088cc;
  background-color: var(--telegram);
  border-color: #0088cc;
  border-color: var(--telegram);
  color: #fff;
}
