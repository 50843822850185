.Bio {
  display: flex;
  margin-bottom: 4.375rem;
}
  
.Bio img {
  border-radius: 50%;
  flex-shrink: 0;
  height: 3.5rem;
  margin-bottom: 0;
  margin-right: 0.875rem;
  width: 3.5rem;
}

.headerLinks {
  display: flex;
  flex-flow: row wrap;
}

.headerLinks .bull {
  margin: 0 5px;
}
